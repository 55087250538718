import React from "react"
import Layout from '../components/layout/layout';
import { injectIntl } from "gatsby-plugin-intl";
import { 
  AboutUsSection,
  ClientSection,
  ContactSection,
  Footer,
  MainHero,
  Header,
  ServiceSection,
  SEO,
  TechnologySection,
} from "../components";

const IndexPage = ({intl}) => {
  return(
    <Layout>
      <SEO title="Industrial Software Development" lang={intl.locale} description={intl.formatMessage({ id: `home.site.description`})}/>
      <Header isMainPage />
      <MainHero/>
      <ServiceSection/>
      <AboutUsSection/>
      <TechnologySection/>
      <ClientSection/>
      <ContactSection page={'home'}/>
      <Footer/>
    </Layout>
)}

export default injectIntl(IndexPage);
